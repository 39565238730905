import React, { useEffect, useState, useRef } from "react";
import useSound from 'use-sound';
import sfxScanOk from 'assets/sounds/zapsplat_multimedia_alert_notification_bell_high_pitched_chime_ring_positive_006_78067.mp3'
// reactstrap components
import {
    Button, Card,
    CardBody,
    CardFooter, CardHeader,
    CardTitle,
    Col,
    Form, FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    Row,
    Table
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

import MfgApiService from "../MfgApiService";
import ReactTable from "../components/ReactTable/ReactTable";
import Common from "../utils/Common.js";
import ShippingSignalRClient from "../components/ShippingSignalRClient";
import ReactDateTime from "react-datetime"
import Tags from "../components/Tags";
import LoaderGears from "../components/LoaderGears";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { transform } from "typescript";
import { Dropdown } from "bootstrap";


// Helper Functions
const voidFunc = () => { }

function isShadeBoxed(shade) {

    console.log(shade);
    if (shade.shade_mfg_statuses.length == 0) return false;

    // BX = 0x0200,    // Shade Scanned at boxing (shipping) station
    return (shade.shade_mfg_statuses[0].current_status & 0x0200)
}

function isHardwareKitBoxed(shade) {

    if (shade.shade_mfg_statuses.length == 0) return false;

    // HK_BOXED = 0x10000 // Hardware Kit Boxed
    return (shade.shade_mfg_statuses[0].current_status & 0x10000)
}

function isShadeAssyBoxed(shadeAssy) {
    let incompleteShadeAssy = false;
    if (shadeAssy.shades.length > 0) {
        shadeAssy.shades.forEach(s => {
            if (!isShadeBoxed(s) || !isHardwareKitBoxed(s)) {
                incompleteShadeAssy = true;

            }
        });
    }


    if (shadeAssy.fascia != null) {
        shadeAssy.fascia.forEach(f => {
            if (!f.is_boxed) {
                incompleteShadeAssy = true;

            }
        });
    }
    // if we get here. Everything must have been completed
    console.log("isShadeAssyBoxed", !incompleteShadeAssy);
    return !incompleteShadeAssy;
}

// Does user have the access to do important functions???
function userRoleHasStationAccess(userRole) {
    if (userRole === "viewer")
        return false;
    else
        return true;
}

function GetTags(shadeAssy, special) {
    const Tags = [];

    if (special) {
        if (shadeAssy.is_tube_and_fabric_only)
            Tags.push("Tube and Fabric");
        if (shadeAssy.is_motor_only)
            Tags.push("Motor");
        if (shadeAssy.is_header_extrusion_only)
            Tags.push("Header");
        if (shadeAssy.is_hardware_only)
            Tags.push("Hardware Kit");
        if (shadeAssy.is_channels_only)
            Tags.push("Channels");
        if (shadeAssy.is_shade_service)
            Tags.push("Customer Returned Shade");
        if (shadeAssy.is_crown_and_drive_only)
            Tags.push("Crown and Drive");
    }
    else {
        Tags.push("Shade", "Hardware Kit");

        if (shadeAssy.fascia.length > 0)
            Tags.push("Fascia")
        if (shadeAssy.channels.length > 0)
            Tags.push("Channels")

    }

    return Tags;

}

// Component
const ShippingStation = () => {


    // State variables
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
    const [currentQuoteId, setCurrentQuoteId] = useState("");
    const quoteIdRef = useRef();
    const [order, setOrder] = useState({});
    const [orderParts, setOrderParts] = useState({});
    const [orderedShadeList, setOrderedShadeList] = useState({});
    const [accessoriesData, setAccessoriesData] = useState([]);
    const [shadeAssyData, setShadeAssyData] = useState([]);
    const [portalShipments, setPortalShipments] = useState([]);
    const [zohoPackages, setZohoPackages] = useState([]);
    const [zohoShipments, setZohoShipments] = useState([]);
    const refZohoPackages = useRef([]);
    const refZohoShipments = useRef([]);
    const [fasciaData, setFasciaData] = useState({});

    const [loading, setLoading] = useState(false);
    const [loadingZohoPackages, setLoadingZohoPackages] = useState(false);
    const [loadingZohoShipments, setLoadingZohoShipments] = useState(false);
    const [zohoPackagePending, setZohoPackagePending] = useState(false);
    const [zohoShipmentPending, setZohoShipmentPending] = useState(false);

    const [shadesComplete, setShadesComplete] = useState(false);
    const [accessoriesComplete, setAccessoriesComplete] = useState(false);
    const [fasciaComplete, setFasciaComplete] = useState(false);
    const [orderComplete, setOrderComplete] = useState(false);
    const [warningTags, setWarningTags] = useState([]); // string array for warnings ex. "Shades Not Complete", "Order Not Provisioned"
    const [successTags, setSuccessTags] = useState([]);
    const [playScanOk] = useSound(sfxScanOk);
    const [alert, setAlert] = React.useState(null);
    const [hasChangeRequest, setChangeRequest] = useState(true);
    const [isTubeFabricOnly, setTubeFabricOnly] = useState(true);
    const [isHeaderOnly, setHeaderOnly] = useState(true);
    const [isHardwareOnly, setHardwareOnly] = useState(true);
    const [isChannelsOnly, setChannelsOnly] = useState(true);
    const [isMotorOnly, setMotorOnly] = useState(true);

    const currentBox = useRef([]);  // use useRef here because receiveing messages from signal R is kind of "multi-threaded"
    const [currentBoxState, setCurrentBoxState] = useState([]); // use this for re-rendering list
    const [shippingStationId, setShippingStationId] = useState("Set Shipping Station ID");
    const [signalRConnected, setSignalRConnected] = useState(false);

    // modals
    const [createZohoShipmentModal, setZohoShipmentModal] = useState(false);
    const createZohoShipmentToggle = () => setZohoShipmentModal(!createZohoShipmentModal);
    const [deliveryMethodList, setDeliveryMethodList] = useState([]);

    const [quoteIdsToShip, setQuoteIdsToShip] = useState([]);
    const quoteIdsToShipRef = useRef([]);

    quoteIdsToShipRef.current = quoteIdsToShip;

    // Component Variables
    let vAccessoryData = [];
    let vFasciaData = [];

    const modalOpened = () => {
        //Get the shipment data from commerce api
        console.log("get zoho shipments");
        setLoadingZohoShipments(true);
        refZohoShipments.current = [];
        setZohoShipments([]);
        MfgApiService.get("/api/commerceapi/shipments/entity/PS/quote/" + order.quote_id).then(
            newData => {
                if (newData) {

                    console.log("zoho shipments", newData);
                    refZohoShipments.current = newData.shipments;
                    setZohoShipments(newData.shipments);
                    setLoadingZohoShipments(false);
                }
                else {
                    console.log("Empty or Null Zoho Shipment Data");
                }
            }
        ).catch(error => console.error("error", error));
    };

    // Zoho functions
    const createZohoPackage = () => {

        let boxNum = refZohoPackages.current.length + 1;
        console.log("boxNum", boxNum);
        console.log("refZohoPackages", refZohoPackages.current);

        let vPayload = {
            "quote_id": order.quote_id,
            "ordering_entity": "PS",
            "box_number": boxNum.toString(),
            "notes": "Test Box"
        };

        console.log("vPayload", vPayload);

        //Create the Package in Zoho
        MfgApiService.post("/api/commerceapi/packages/create", vPayload).then(
            response => {

                populateOrderData();
                setZohoPackagePending(true);

            }
        ).catch(error => console.error("error", error));
    }

    // Alert functions
    const hideAlert = () => {
        setAlert(null);
    };

    const inputShippingStationIdAlert = () => {
        setAlert(
            <ReactBSAlert
                input
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="Input New Station ID"
                onConfirm={(e) => inputConfirmAlert(e)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                btnSize=""
                defaultValue={shippingStationId}
            />
        );
    };

    const inputConfirmAlert = (e) => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="success"
                btnSize=""
                title="You entered: "
            >
                <b>{e}</b>
            </ReactBSAlert>
        );

        setShippingStationId(e);
        localStorage.setItem("system.station.shippingStationId", e);
    };

    const warningWithConfirmMessage = (title, message) => {
        setAlert(
            <ReactBSAlert
                danger
                title={title}
                onConfirm={() => setAlert(null)}
                confirmBtnBsStyle="danger"
                confirmBtnText="OK"
                btnSize=""
            >
                {message}
            </ReactBSAlert>
        );
    };


    const funcPlaySound = async () => {
        await playScanOk();
    }

    const handleDataReceived = async (data) => {
        console.log("message", data);

        let jsonData = JSON.parse(data);

        // the message from API Hub will be the entire shade in json format

        console.log("itemType", jsonData.itemType);

        //Handle Shades, HKs, and Fascias
        if (jsonData.itemType === "SH") {
            console.log("SH");

            // Make sure the quote ids match
            if (jsonData.shade.shade_assemblies.orders.quote_id != quoteIdRef.current) {
                warningWithConfirmMessage("Incorrect Order", "The item scanned does not belong to the order.");
                return;
            }

            currentBox.current.push(
                {
                    item: jsonData.message,
                    description: /*"SA" + jsonData.shade.shade_assemblies.id + " " + */jsonData.shade.shade_assemblies.shade_name + " W" + jsonData.shade.width + " x H" + jsonData.shade.height,
                    room: jsonData.shade.shade_assemblies.room_name,
                    floor: jsonData.shade.shade_assemblies.floor,

                })
        }
        else if (jsonData.itemType === "HK") {
            console.log("HK");

            // Make sure the quote ids match
            if (jsonData.shade.orders.quote_id != quoteIdRef.current) {
                warningWithConfirmMessage("Incorrect Order", "The item scanned does not belong to the order.");
                return;
            }

            // For hardware kits...we care about the shade it belongs to.
            //SH" + jsonData.shade.id + " SA" + jsonData.shade.id +
            currentBox.current.push(
                {
                    item: jsonData.message,
                    description: "Hardware Kit for Shade " + jsonData.shade.shade_name
                })
        }
        else if (jsonData.itemType === "FA") {
            console.log("FA");

            // Make sure the quote ids match
            if (jsonData.fascia.shade_assemblies.orders.quote_id != quoteIdRef.current) {
                warningWithConfirmMessage("Incorrect Order", "The item scanned does not belong to the order.");
                return;
            }

            currentBox.current.push(
                {
                    item: jsonData.message,
                    description: "FASCIA W" + jsonData.fascia.width + " " + jsonData.fascia.shade_assemblies.fascia_color + " " + jsonData.fascia.shade_assemblies.header_type + /*" SA" + jsonData.fascia.shade_assemblies.id*/ + " " + jsonData.fascia.shade_assemblies.shade_name
                })
        }
        else if (jsonData.itemType === "CH") {
            console.log("CH");
            
            // Make sure the quote ids match
            if (jsonData.channels.shade_assemblies_.orders.quote_id != quoteIdRef.current) {
                warningWithConfirmMessage("Incorrect Order", "The item scanned does not belong to the order.");
                return;
            }

            currentBox.current.push(
                {
                    item: jsonData.message,
                    description: "Channel Length" + jsonData.channels.CutValue + " " + jsonData.channels.shade_assemblies_.shade_name
                })
        }
        else if (jsonData.itemType === "BI") {
            currentBox.current.push({
                item: jsonData.message,
                description: "Accessories Box",
            })
            console.log("BI");
        }
        else if (jsonData.itemType === "AC") {
            console.log("AC");
        }
        else {
            return "ERROR";
        }

        console.log("currentBox", currentBox.current);
        setCurrentBoxState(currentBox.current);

        await populateOrderData();
        await funcPlaySound();
    }

    const clearBox = (e) => {
        e.preventDefault();
        currentBox.current = [];
        setCurrentBoxState(currentBox);
        console.log("currentBox", currentBox.current);
    }

    const finishBox = (e) => {
        e.preventDefault();

        // tell the shipping station service app to print a label

        let vOrderData = {
            order_id: order.id,
            quote_id: order.quote_id,
            reference_number: order.reference_number,
            title: order.title,
            items: currentBoxState
        }

        // Send the Print Request to the Service App
        let vPayload = {
            type: "BOX_LABEL",
            data: JSON.stringify(vOrderData)
        };

        MfgApiService.post('/api/shippinghub/printrequest/stationid/' + shippingStationId, vPayload)
            .then(data => {
                console.log("printrequest response", data);
                currentBox.current = [];
                setCurrentBoxState(currentBox);
            });

        console.log("currentBox", currentBox.current);
    }

    const putOrderComplete = () => {
        MfgApiService.post(`/api/orders/${order.id}/setcomplete?station=${shippingStationId}`, {})
            .then(data => {
                console.log("putorder", data);
                populateOrderData();
            });
    }


    // Fetch order data
    const populateOrderData = async () => {

        // define the function
        let doWork = async () => {
            setLoading(true);

            let _order = {};

            await MfgApiService.get('/api/orders/quoteidList/' + quoteIdRef.current) //+'?accessories=true')
                .then(data => {
                    //console.log('Success!', data);
                    if (data != null) {

                        setQuoteIdsToShip([data.order.quote_id]);

                        setOrder(data.order);
                        setOrderedShadeList(data.shadesList);
                        _order = data.order;
                        setChangeRequest(_order.has_change_request);

                        //  look at the box_contents and make a sku, qty list for box_contents that have a date_shipped value
                        let boxContentTotal = {};
                        _order.box_contents.map(bc => {
                            if (bc.shipping_date != null) {
                                JSON.parse(bc.contents).map(b => {

                                    // see if we already have a key with this sku name
                                    if (b.itemDescription.includes("GW:")) {
                                        b.itemSku = "PSPRO-RF433-INT";
                                    }

                                    if (boxContentTotal[b.itemSku] != null) {
                                        // if the key exists, add the itemQuantity of the box_contents
                                        boxContentTotal[b.itemSku] = boxContentTotal[b.itemSku] + Number(b.itemQuantity);
                                    }
                                    else {
                                        boxContentTotal[b.itemSku] = Number(b.itemQuantity);
                                    }
                                })
                            }
                        });

                        console.log("boxContentTotal", boxContentTotal);


                        // Add the qty_boxed field to accessories
                        vAccessoryData = _order.order_accessories.map(a => {

                            // find the qty of this sku from accessoryCount
                            //let v = accessoryCount.filter(f => { return f.sku === a.inventory_sku })
                            //console.log("v", v);

                            //let packedQty = 0;
                            //if (v.length > 0) {
                            //    packedQty = v[0].qty;
                            //}

                            let packedQty = 0;
                            if (boxContentTotal[a.inventory_sku] != null)
                                packedQty = boxContentTotal[a.inventory_sku];

                            //let temp = {
                            //    ...a, qty_boxed: packedQty  // should only be one so use element 0
                            //}
                            //return temp;
                            return a;
                        });

                        setAccessoriesData(vAccessoryData);
                        console.log("vAccessoryData", vAccessoryData);


                        

                        // Set Shade Assembly Data
                        setShadeAssyData(_order.shade_assemblies);

                        setFasciaData(data.fasciaList);
                        //Get the shipment data from portal list
                        MfgApiService.get("/api/portalapi/shipments/" + _order.quote_id).then(
                            newData => {
                                if (newData) {
                                    setPortalShipments(newData.quote_shipments);
                                    //console.log("portal shipments", newData.quote_shipments);
                                }
                                else {
                                    setPortalShipments({});
                                }
                            }
                        ).catch(error => console.error("error", error));


                        //Get the package data from commerce api
                        console.log("get zoho packages");
                        setLoadingZohoPackages(true);
                        refZohoPackages.current = [];
                        setZohoPackages([]);
                        MfgApiService.get("/api/commerceapi/packages/entity/PS/quote/" + _order.quote_id).then( //_order.quote_id).then(
                            newData => {
                                if (newData) {

                                    console.log("zoho packages", newData);
                                    refZohoPackages.current = newData.packages;
                                    setZohoPackages(newData.packages);
                                    setLoadingZohoPackages(false);
                                }
                                else {

                                }
                            }
                        ).catch(error => console.error("error", error));


                        //Get the shipment data from commerce api
                        console.log("get zoho shipments");
                        setLoadingZohoShipments(true);
                        refZohoShipments.current = [];
                        setZohoShipments([]);
                        MfgApiService.get("/api/commerceapi/shipments/entity/PS/quote/" + _order.quote_id).then( //_order.quote_id).then(
                            newData => {
                                if (newData) {

                                    console.log("zoho shipments", newData);
                                    refZohoShipments.current = newData.shipments;
                                    setZohoShipments(newData.shipments);
                                    setLoadingZohoShipments(false);
                                }
                                else {
                                    console.log("Empty or Null Zoho Shipment Data");
                                }
                            }
                        ).catch(error => console.error("error", error));

                        // get list of delivery methods
                        MfgApiService.get(`/api/commerceapi/shipments/deliverymethods/entity/${_order.ordering_entity}/order/${_order.quote_id}`).then(
                            //MfgApiService.get(`/api/commerceapi/shipments/deliverymethods/entity/PS/order/${_order.quote_id}`).then(
                            data => {
                                console.log("deliverymethods", data);
                                let list = [];
                                data.delivery_methods.map(dm => {
                                    list.push(dm.delivery_method)
                                });
                                setDeliveryMethodList(list);
                            }
                        ).catch(error => console.error("error", error));


                        // get the current order status
                        console.log("order status for ", _order.id);
                        MfgApiService.get(`/api/orders/${_order.id}/status`).then(
                            data => {
                                console.log("order status", data);

                                // validate the order and set warnings
                                validateOrder(_order, data);
                            }
                        ).catch(error => console.error("error", error));

                    };
                })
                .catch(error => {
                    console.error("Cannot find order " + quoteIdRef.current, error);
                    warningWithConfirmMessage("Search Error", "Cannot find order " + quoteIdRef.current);
                });


            //await MfgApiService.get('/api/orderparts?orderId=' + _order.id)
            //    .then(data => {
            //        console.log('Success!', data);
            //        if (data != null) {
            //            setOrderParts(data);
            //            setLoading(false);
            //            console.log("orderParts", data);
            //        };

            //    })
            //    .catch(error => console.error("error", error));
        }

        // now call the function
        await doWork();
        setLoading(false);

    }


    function copyText(entryText) {
        navigator.clipboard.writeText(entryText);
    }

    // Note: might do this in orders controller
    function validateOrder(vOrder, statusData) {

        console.info("Validating Order", vOrder, statusData);

        //let incompleteShadeDetected = false; // shades and hardware kits scanned
        //let incompleteFasciaDetected = false;
        //let accQtyMismatch = false;
        let tempWarnings = [];

        //// Iterate through the orders and check bits
        //if (vOrder.shade_assemblies.length > 0) {
        //    vOrder.shade_assemblies.map(sa => {

        //        sa.shades.map(s => {

        //            if (!isShadeBoxed(s)) {
        //                incompleteShadeDetected = true;
        //            }

        //            if (!isHardwareKitBoxed(s)) {
        //                incompleteShadeDetected = true;
        //            }
        //        });

        //        sa.fascia.map(f => {
        //            if (!f.is_boxed) {
        //                incompleteFasciaDetected = true;
        //                console.log("incompleteFasciaDetected");
        //            }
        //        })
        //    });

        //    setShadesComplete(!incompleteShadeDetected && !incompleteFasciaDetected);

        //}
        //else {
        //    setShadesComplete(true);
        //}


        //console.log("vAccessoryData.length",vAccessoryData.length)
        //if (vAccessoryData.length > 0) {
        //    vAccessoryData.map(a => {
        //        if (a.qty_boxed != a.qty_ordered) {
        //            accQtyMismatch = true;
        //        }
        //    });
        //    console.log("accQtyMismatch", accQtyMismatch);
        //    setAccessoriesComplete(!accQtyMismatch);

        //}
        //else {
        //    //no accessories so mark as complete
        //    setAccessoriesComplete(true);
        //}


        setAccessoriesComplete(statusData.isAccessoriesComplete);
        if (!statusData.isAccessoriesComplete)
            tempWarnings.push("Accessories Incomplete");

        setShadesComplete(statusData.isShadesComplete);
        if (!statusData.isShadesComplete)
            tempWarnings.push("Shades Incomplete");

        setFasciaComplete(statusData.isFasciaComplete)
        if (!statusData.isFasciaComplete)
            tempWarnings.push("Fascia Incomplete");

        if (!statusData.isComplete)
            tempWarnings.push("Order Not Completed");

        if (statusData.isProvisionRequired) {
            if (!statusData.isProvisioned) {
                tempWarnings.push("Order Not Provisioned");
            }

            if (statusData.isPushToDashboardRequired) {
                if (!statusData.isPushedToDashboard) {
                    tempWarnings.push("Order Not Pushed to Dashboard");
                }
            }

        }

        if (statusData.hasChangeRequest) {
            tempWarnings.push("Pending Change Request");
        }

        console.info("Temp Warnings", tempWarnings);

        setWarningTags(tempWarnings);
        setSuccessTags(tempWarnings.length > 0 ? [] : ["ORDER COMPLETE!"])
        //console.log("Warnings", tempWarnings);
    }


    //
    // USE EFFECT
    //
    useEffect(() => {

        // Populate the Shipping Station Id
        let vShippingStationId = localStorage.getItem("system.station.shippingStationId");
        if (vShippingStationId != null) {
            setShippingStationId(vShippingStationId);
        }
    },
        []
    );

    // Handler Functions
    const handleOnKeyUp = (event) => {
        if (event.key === 'Enter') {
            populateOrderData();

        }
    }

    const handleCreateShipmentModalClose = () => {
        setZohoShipmentModal(false);
    }

    // Render
    return (
        <>
            <div className="content">

                {/* LOADING SPINNER */}
                {loading ? (
                    <LoaderGears />
                ) : null}

                {/* ALERTS */}
                {alert}

                {/* MODALS */}
                <CreateZohoShipmentModal
                    isOpen={createZohoShipmentModal}
                    toggle={createZohoShipmentToggle}
                    packages={zohoPackages}
                    onCancel={handleCreateShipmentModalClose}
                    shipments={refZohoShipments.current}
                    order={order}
                    deliveryMethodList={deliveryMethodList}
                    onOpened={modalOpened}
                    quoteIdsToShipRef={quoteIdsToShipRef}
                    setQuoteIdsToShip={setQuoteIdsToShip}
                />


                {/* CARDS */}
                <Row>
                    <Col xs={7} md={7}>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Label sm="2">Search for Order:</Label>
                                    <Col sm="3">

                                        <Input type="text"
                                            value={currentQuoteId}
                                            onChange={(e) => {
                                                setCurrentQuoteId(e.target.value);
                                                quoteIdRef.current = e.target.value;
                                            }}
                                            onKeyUp={handleOnKeyUp}
                                        />
                                    </Col>
                                    <Col sm="3">
                                        <Button
                                            style={{
                                                margin: "0 0 0 0",
                                                padding: "8 8 8 8",
                                                height: 38
                                            }}
                                            className="btn-fill"
                                            color="info"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                populateOrderData();
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                    <Col sm="4">
                                        <Tags
                                            tagProps={{ className: "react-tagsinput-tag danger" }}
                                            value={warningTags}
                                            disabled
                                            onChange={voidFunc}
                                        />
                                        <Tags
                                            tagProps={{ className: "react-tagsinput-tag success-green" }}
                                            value={successTags}
                                            disabled
                                            onChange={voidFunc}

                                        />
                                    </Col>

                                </Row>

                            </CardBody>
                            <CardFooter>
                                <Button
                                    style={{
                                        margin: "0 0 0 0",
                                        padding: "8 8 8 8",
                                        height: 38
                                    }}
                                    className="btn-fill pull-right"
                                    color="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        putOrderComplete();
                                    }}
                                    disabled={hasChangeRequest}
                                >
                                    Mark Complete
                                </Button>
                            </CardFooter>
                        </Card>
                        <div className='scroll-panel' style={{ overflowY: 'scroll', maxHeight: '75vh' }}>

                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{shadeAssyData.length > 0 ? "Shades " : "No Shades in Order"}
                                    <strong className="pull-right" style={{ color: shadesComplete ? "green" : "red" }}>{shadesComplete ? "COMPLETE" : "INCOMPLETE"}</strong>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {shadeAssyData.length > 0 ? (
                                    <Table className="-highlight table-bordered">
                                        <thead>
                                            <tr style={{ backgroundColor: "#E3E3E3", textAlign: "center" }}>
                                                <th colSpan="4" className="bordered header">Shade Assembly Information</th>
                                                {/*<th colSpan="1" className="bordered header">Boxed</th>*/}
                                                {/*<th colSpan="6" className="bordered header">Special Cases</th>*/}
                                            </tr>
                                            <tr style={{ backgroundColor: "#E3E3E3", textAlign: "center" }}>
                                                <th className="bordered header">Sh. Assy</th>
                                                <th className="bordered header">Item</th>
                                                <th className="bordered header">Detail</th>
                                                <th className="bordered header">Boxed</th>
                                                {/*<th className="bordered header">T&F</th>*/}
                                                {/*<th className="bordered header">Header</th>*/}
                                                {/*<th className="bordered header">Motor</th>*/}
                                                {/*<th className="bordered header">Hardware</th>*/}
                                                {/*<th className="bordered header">Channels</th>*/}
                                                {/*<th className="bordered header">Cutdown</th>*/}
                                                

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ShadeAssemblyTable order={order} shadeList={orderedShadeList} />
                                        </tbody>
                                    </Table>
                                ) : null}

                                {/*<br />*/}
                                {/*<hr color="red" />*/}
                                {/*<hr color="red" />*/}
                                {/*<hr color="red" />*/}
                                {/*<br />*/}


                                {/*<div>*/}
                                {/*    {order.shade_assemblies ? order.shade_assemblies.map(sa => {*/}
                                {/*        console.log(sa);*/}
                                {/*        return <ShadeAssyItem color="white" shadeAssy={sa} />*/}
                                {/*    }) : null}*/}
                                {/*</div>*/}
                            </CardBody>
                        </Card>


                        {/*<Card>*/}
                        {/*    <CardHeader>*/}
                        {/*        <CardTitle tag="h4">{shadeAssyData.length > 0 ? "Shades " : "No Shades in Order"} <strong class="pull-right" color="green">COMPLETE</strong></CardTitle>*/}
                        {/*    </CardHeader>*/}
                        {/*    <CardBody>*/}
                        {/*        {shadeAssyData.length > 0 ? (*/}
                        {/*            <ReactTable id="rtShades"*/}
                        {/*                className="-striped -highlight"*/}
                        {/*                columns={shadeAssyColumns}*/}
                        {/*                data={shadeAssyData}*/}
                        {/*                showPaginationBlock={false}*/}
                        {/*            />*/}
                        {/*        ) : null}*/}
                        {/*    </CardBody>*/}
                        {/*    <CardFooter>*/}
                        {/*    </CardFooter>*/}
                        {/*</Card>*/}

                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{fasciaData.length > 0 ? "Fascias" : "No Fascias In Order"}
                                    <strong className="pull-right" style={{ color: fasciaComplete ? "green" : "red" }}>{fasciaComplete ? "COMPLETE" : "INCOMPLETE"}</strong>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {fasciaData.length > 0 ? (

                                    <Table className="-highlight table-bordered">
                                        <thead>
                                            <tr style={{ backgroundColor: "#E3E3E3", textAlign: "center" }}>
                                                <th className="bordered header">ID</th>
                                                <th className="bordered header">SKU</th>
                                                <th className="bordered header">DESCRIPTION</th>
                                                <th className="bordered header">WIDTH</th>
                                                <th className="bordered header">BOXED</th>
                                                <th className="bordered header">SHADE ID(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <FasciaTableBody fasciaData={fasciaData} />
                                        </tbody>
                                    </Table>


                                    //<ReactTable id="rtAccessories"
                                    //    className="-striped -highlight table-bordered lightgray-headers"
                                    //    showPaginationBlock={false}
                                    //    columns={accColumns}
                                    //    data={accessoriesData}
                                    ///>
                                ) : null}
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{accessoriesData.length > 0 ? "Accessories" : "No Accessories in Order"}
                                    <strong className="pull-right" style={{ color: accessoriesComplete ? "green" : "red" }}>{accessoriesComplete ? "COMPLETE" : "INCOMPLETE"}</strong>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {accessoriesData.length > 0 ? (

                                    <Table className="-highlight table-bordered">
                                        <thead>
                                            <tr style={{ backgroundColor: "#E3E3E3", textAlign: "center" }}>
                                                <th className="bordered header">ID</th>
                                                <th className="bordered header">SKU</th>
                                                <th className="bordered header">DESCRIPTION</th>
                                                <th className="bordered header">QTY</th>
                                                <th className="bordered header">BOXED</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <AccessoriesTableBody accessoriesData={accessoriesData} />
                                        </tbody>
                                    </Table>


                                    //<ReactTable id="rtAccessories"
                                    //    className="-striped -highlight table-bordered lightgray-headers"
                                    //    showPaginationBlock={false}
                                    //    columns={accColumns}
                                    //    data={accessoriesData}
                                    ///>
                                ) : null}
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>

                        </div>
                    </Col>



                    <Col xs={5} md={5}>
                        <div className='scroll-panel' style={{ overflowY: 'scroll', maxHeight: '75vh' }}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Current Box Contents
                                    <Button className="btn-link" color={signalRConnected ? "primary" : "danger"} onClick={inputShippingStationIdAlert} style={{ marginLeft: "10px" }}>{shippingStationId}</ Button>
                                    <Button className="btn btn-sm pull-right" onClick={clearBox}>Clear</Button>
                                    <Button className="btn btn-sm pull-right" onClick={finishBox}>Finish Box</Button>

                                </CardTitle>
                                <CardBody>
                                    <div style={{ listStyleType: "none" }}>
                                        {currentBoxState.length > 0 ? currentBoxState.map(cb => {
                                            return (
                                                <div key={cb.item}
                                                    style={{
                                                        borderColor: "#638b2c",
                                                        borderWidth: "1",
                                                        borderStyle: "solid",
                                                        margin: "10px",
                                                        padding: "10px",
                                                        borderRadius: ".5rem",
                                                        height: "fit-content",
                                                        boxShadow: "inset 0 -2em 2em rgba(0,0,0,0.1)"
                                                    }}
                                                >

                                                    <Label
                                                        style={{ margin: "0px" }}
                                                        tag="h4">{cb.item}

                                                        <Button
                                                            data={cb}
                                                            style={{}}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                console.log("remove", cb);
                                                                currentBox.current = currentBox.current.filter(item => item !== cb);  //ex item = "SH12345"
                                                                setCurrentBoxState(currentBox.current);

                                                            }}
                                                            color="danger"
                                                            size="sm"
                                                            className="btn-icon btn-simple btn-danger pull-right"
                                                        >
                                                            <i className="tim-icons icon-simple-remove" />
                                                        </Button>{" "}
                                                    </Label>
                                                    <p>{cb.description}</p>
                                                </div>);
                                        }) : null
                                        }
                                    </div>
                                </CardBody>
                            </CardHeader>

                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Order Information:  <strong color="yellow">{order.quote_id}</strong></CardTitle>
                                <p><strong>Title:</strong><span>  {order.title}</span></p>
                                <p><strong>Type:</strong><span>  {order.orderType}</span></p>
                                <p><strong>Reference #:</strong><span>  {order.reference_number}</span></p>
                                <p><strong>Dealer:</strong><span>  {order.dealer}</span></p>
                                <p><strong>Distributor:</strong><span>  {order.distributor}</span></p>
                                <p><strong>Rep:</strong><span>  {order.representative}</span></p>
                            </CardHeader>
                            <CardBody>

                                <FormGroup className="mt-3">
                                    <Label>Shipping Information:</Label>
                                    <span>
                                        <Button className="btn btn-info btn-xsm pull-right"
                                            onClick={() => copyText(document.getElementById('shipping-address').value)}
                                        >
                                            Copy
                                        </Button>
                                    </span>
                                    <Input
                                        id="shipping-address"
                                        style={{ "lineHeight": 1.3 }}
                                        type="textarea"
                                        className="bigger-text-area"

                                        defaultValue={order.id ? (order.distributor == "Evolution Home Entertainment Corp" && (order.orderType != "rma" && order.orderType != "rework")) ? "266 Applewood Cres.\n\nConcord Ontario L4K 4B4" :
                                            (order.shipping_company + "\n" +
                                                order.shipping_address1 + "\n" +
                                                order.shipping_address2 + "\n" +
                                                order.shipping_city + " " + order.shipping_country_subdivision + " " + order.shipping_zip + "\n" +
                                                order.shipping_country)
                                            : ""}
                                    />
                                </FormGroup>


                                <FormGroup className="mt-3">
                                    <Label>Portal Notes</Label>
                                    <Input type="textarea"
                                        className="bigger-text-area"
                                        defaultValue={order ? order.notes : ""}
                                        readOnly={true}
                                    />
                                </FormGroup>


                                <FormGroup className="mt-3">
                                    <Label>Reason Delayed</Label>
                                    <Input type="textarea"
                                        defaultValue={order ? order.reasonDelayed : ""}
                                        readOnly={true}
                                    />
                                </FormGroup>

                                <FormGroup className="mt-3">
                                    <Label>Expected Ship Date:</Label>
                                    <Input type="text"
                                        defaultValue={Common.convertDateToFriendlyDate(order.expectedShipDate)}
                                        readOnly={true}
                                    />
                                </FormGroup>

                                <FormGroup className="mt-3">
                                    <Label>Manufacturing Notes</Label>
                                    <Input type="textarea"
                                        className="bigger-text-area"
                                        defaultValue={order ? order.mfgNotes : ""}
                                        readOnly={true}
                                    />
                                </FormGroup>

                                <FormGroup className="mt-3">
                                    <Label>Portal Shipments</Label>
                                    <Table
                                        className="-striped -highlight"
                                        style={{ width: "600px" }}
                                    >
                                        <thead>
                                            <tr>
                                                <th className="header margin-20">Carrier</th>
                                                <th className="header margin-20">Time</th>
                                                <th className="header margin-20">Tracking Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {portalShipments.length > 0 ? portalShipments.map(s => {
                                                return (
                                                    <tr id={s.id}>
                                                        <td>{s.carrier}</td>
                                                        <td>{Common.convertUnixDateToDateString(s.time)}</td>
                                                        <td>{s.tracking_number}</td>
                                                    </tr>
                                                )
                                            }) : null}
                                        </tbody>
                                    </Table>
                                </FormGroup>

                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle>Zoho Packages {loadingZohoPackages && (<i className="fa fa-cog fa-spin margin-left-10" />)}

                                    <Button disabled="true" title="Temporarily Inactive" hidden={!userRoleHasStationAccess(user.mfgApiRole.roleName)} className="btn btn-sm pull-right" onClick={createZohoPackage}>Create</Button>
                                    {zohoPackagePending && (<Tags onChange={voidFunc} value={["Create Package Pending. Refresh browser in 5 minutes."]} />)}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    className="-striped -highlight"
                                >
                                    <thead>
                                        <tr>
                                            <th className="header">Pkg Id</th>
                                            <th className="header">Date</th>
                                            <th className="header">Package #</th>
                                            <th className="header">Sales Order #</th>
                                            <th className="header">Status</th>
                                            <th className="header">Shipment Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            zohoPackages.map(p => {
                                                return (
                                                    <tr>
                                                        <td>{p.package_id}</td>
                                                        <td>{p.date}</td>
                                                        <td>{p.package_number}</td>
                                                        <td>{p.salesorder_number}</td>
                                                        <td>{p.status}</td>
                                                        <td>{p.shipment_id}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle>Zoho Shipments
                                    {loadingZohoShipments && (<i className="fa fa-cog fa-spin margin-left-10" />)}
                                    <Button hidden={!userRoleHasStationAccess(user.mfgApiRole.roleName)} className="btn btn-sm pull-right" onClick={() => { setZohoShipmentModal(true) }}>Create</Button>
                                    {zohoShipmentPending && (<Tags onChange={voidFunc} value={["Create Shipment Pending. Refresh browser in 5 minutes."]} />)}
                                </CardTitle>

                            </CardHeader>
                            <CardBody>
                                <Table
                                    className="-striped -highlight"
                                >
                                    <thead>
                                        <tr>
                                            <th className="header">Shipment Id</th>
                                            <th className="header">Number</th>
                                            <th className="header">Status</th>
                                            <th className="header">Date</th>
                                            <th className="header">Carrier</th>
                                            <th className="header">Tracking</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            zohoShipments.map(s => {
                                                return (
                                                    <tr>
                                                        <td>{s.shipment_id}</td>
                                                        <td>{s.shipment_number}</td>
                                                        <td>{s.status}</td>
                                                        <td>{s.date}</td>
                                                        <td>{s.carrier}</td>
                                                        <td>{s.tracking_number}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle>Box Contents</CardTitle>

                            </CardHeader>
                            <CardBody>

                                {Object.keys(order).length > 0 ? (
                                    <Table className="-highlight table-bordered -striped">
                                        <thead>
                                            <tr>
                                                <th className="header">Id</th>
                                                <th className="header">Date</th>
                                                <th className="header">Contents</th>
                                                <th className="header">Date Shipped</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {order.box_contents.length > 0 ? order.box_contents.map(b => {
                                                return (
                                                    <tr id={b.id}>
                                                        <td>{b.id}</td>
                                                        <td>{Common.convertDateToFriendlyDate(b.date)}</td>
                                                        <td>
                                                            <Table style={{ backgroundColor: "whitesmoke" }}>
                                                                <tbody>
                                                                    {JSON.parse(b.contents).map(x => {
                                                                        return (
                                                                            <tr id={x.id}>
                                                                                <td>{x.itemSku}</td>
                                                                                <td>{x.itemDescription}</td>
                                                                                <td>{x.itemQuantity}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        </td>
                                                        <td>
                                                            {b.shipping_date &&
                                                                <Tags
                                                                    tagProps={{ className: "react-tagsinput-tag success-green" }}
                                                                    value={[Common.convertDateToFriendlyDate(b.shipping_date)]}
                                                                    disabled
                                                                    onChange={voidFunc}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            ) : null}

                                        </tbody>
                                    </Table>
                                ) : null}
                            </CardBody>
                        </Card>
                        </div>
                    </Col>
                </Row>




                <Row>
                    <Col xs={4} md={4}>
                        <ShippingSignalRClient OnDataReceived={handleDataReceived} OnConnectionChanged={setSignalRConnected} />
                    </Col>
                </Row>
            </div>

        </>);
}


const ShadeAssemblyTable = (props) => {

    let rowColor = "#1d253d";
    let order = props.order;
    const shadeList = props.shadeList;
    order.shade_assemblies.sort((a, b) => shadeList.indexOf(a.id) - shadeList.indexOf(b.id));
    return (<>
        {
            order.shade_assemblies ? order.shade_assemblies.map(sa => {

                //console.log(sa)
                if (rowColor === "transparent")
                    rowColor = "whitesmoke";
                else
                    rowColor = "transparent"

                return <ShadeAssyTableBody id={sa.id} shadeAssy={sa} rowColor={rowColor} sl={shadeList} />
            }) : null
        }
    </>);
}


// Build the table body for shade assembly
const ShadeAssyTableBody = (props) => {

    const shadeAssy = props.shadeAssy;
    const sl = props.sl;
    const isSpecial = (shadeAssy.is_tube_and_fabric_only || shadeAssy.is_header_extrusion_only || shadeAssy.is_motor_only || shadeAssy.is_hardware_only || shadeAssy.is_channels_only || shadeAssy.is_shade_service)
    return (
        <>
            

            <tr key={"SA" + shadeAssy.id} style={{ backgroundColor: isShadeAssyBoxed(shadeAssy) ? "#ddeec5" : props.rowColor }}>
                <td >{shadeAssy.shade_name}</td>
                <td>{"SA" + shadeAssy.id}</td>
                <td>
                    <Tags
                        tagProps={{ className: "react-tagsinput-tag success-green" }}
                        value={GetTags(shadeAssy, isSpecial)}
                        disabled
                        onChange={voidFunc} />
                </td>
                <td />               
                {/*<Tags>{shadeAssy.is_tube_and_fabric_only ? "Yes" : "No"}</Tags>*/}
                {/*<td>{shadeAssy.is_header_extrusion_only ? "Yes" : "No"}</td>*/}
                {/*<td>{shadeAssy.is_motor_only ? "Yes" : "No"}</td>*/}
                {/*<td>{shadeAssy.is_hardware_only ? "Yes" : "No"}</td>*/}
                {/*<td>{shadeAssy.is_channels_only ? "Yes" : "No"}</td>*/}
                {/*<td>{shadeAssy.is_shade_service ? "Yes" : "No"}</td>*/}

            </tr>

            {
                shadeAssy.shades.map(s => {
                    if ((isSpecial && (shadeAssy.is_tube_and_fabric_only || shadeAssy.is_motor_only)) || !isSpecial) {
                        return (
                            <tr key={"SH" + s.id} style={{ backgroundColor: isShadeBoxed(s) && isHardwareKitBoxed(s) ? "#ddeec5" : props.rowColor }}>
                                <td></td>
                                <td>{"SH" + s.id}</td>
                                <td>{s.motor + " " + s.fabric_name + " " + "WIDTH=" + s.width + " " + "HEIGHT=" + s.height}</td>
                                {/*                            <td style={{ textAlign: "center" }}><strong style={{ textAlign: "center", color: isHardwareKitBoxed(s) ? "green" : "red" }}>{isHardwareKitBoxed(s) ? "YES" : "NO"}</strong></td>*/}
                                <td style={{ textAlign: "center" }} ><strong style={{ color: isShadeBoxed(s) ? "green" : "red" }}>{isShadeBoxed(s) ? "YES" : "NO"}</strong></td>
                            </tr>
                        )
                    }
                })
            }

            {
                //    shadeAssy.fascia.map(f => {
                //        if (f.is_custom_fit && Number(f.custom_fit_value) > 0) {
                //            return (
                //                <tr key={"FA" + f.id} style={{ backgroundColor: f.is_boxed ? "#ddeec5" : props.rowColor }}>
                //                    <td></td>
                //                    <td>{"FA" + f.id}</td>
                //                    <td>{f.itemDescription + " WIDTH=" + f.custom_fit_value}</td>
                //                    {/*                            <td>-</td>*/}
                //                    <td style={{ textAlign: "center" }}><strong style={{ color: f.is_boxed === 1 ? "green" : "red" }}>{f.is_boxed === 1 ? "YES" : "NO"}</strong></td>
                //                </tr>
                //            )
                //        }
                //        else if(!f.is_custom_fit) {
                //            return (
                //                <tr key={"FA" + f.id} style={{ backgroundColor: f.is_boxed ? "#ddeec5" : props.rowColor }}>
                //                    <td></td>
                //                    <td>{"FA" + f.id}</td>
                //                    <td>{f.itemDescription + " WIDTH=" + f.width}</td>
                //                    {/*                            <td>-</td>*/}
                //                    <td style={{ textAlign: "center" }}><strong style={{ color: f.is_boxed === 1 ? "green" : "red" }}>{f.is_boxed === 1 ? "YES" : "NO"}</strong></td>
                //                </tr>
                //            )
                //        }
                //    })
            }
            

            {
                shadeAssy.channels.map(c => {
                    return (
                        <tr key={"CH" + c.id} style={{ backgroundColor: c.isBoxed ? "#ddeec5" : props.rowColor }}>
                            <td></td>
                            <td>{"CH" + c.id}</td>
                            <td>{c.sku + " Length = " + c.cutValue}</td>
                            {/*<td>-</td>*/}
                            <td style={{ textAlign: "center" }}><strong style={{ color: c.isBoxed === 1 ? "green" : "red" }}>{c.isBoxed === 1 ? "YES" : "NO"}</strong></td>
                        </tr>
                    )
                })
            }

            {
                shadeAssy.hardware_kit.map(hk => {
                    return (
                        <tr key={"HK" + hk.id} style={{ backgroundColor: hk.is_boxed ? "#ddeec5" : props.rowColor }}>
                            <td></td>
                            <td>{"HK" + hk.id}</td>
                            <td>{hk.type}</td>
                            {/*<td>-</td>*/}
                            <td style={{ textAlign: "center" }}><strong style={{ color: hk.is_boxed === 1 ? "green" : "red" }}>{hk.is_boxed === 1 ? "YES" : "NO"}</strong></td>
                        </tr>
                    )
                })
            }


        </>
    );
}

const FasciaTableBody = (props) => {

    const fasciaData = props.fasciaData;

    return (
        <>
            {
                fasciaData.map(fa => {
                    return (
                        <tr id={fa.fascia.id} style={{ backgroundColor: fa.fascia.is_boxed ? "#ddeec5" : "" }}>
                            <td>FA{fa.fascia.id}</td>
                            <td>{fa.fascia.sku}</td>
                            <td>{fa.fascia.itemDescription}</td>
                            <td style={{ textAlign: "center" }}>{fa.fascia.is_custom_fit ? fa.fascia.custom_fit_value : fa.fascia.width}</td>
                            <td style={{ backgroundColor: props.rowColor, textAlign: "center", color: fa.fascia.is_boxed === 1 ? "green" : "red" }}>{fa.fascia.is_boxed ? "Yes" : "No"}</td>
                            <td>{fa.shadeIDs}</td>
                        </tr>
                    )
                })
            }
        </>
    );
}

const AccessoriesTableBody = (props) => {

    const assyData = props.accessoriesData;

    return (
        <>
            {
                assyData.map(acc => {
                    return (
                        <tr id={acc.id} style={{ backgroundColor: acc.qty_ordered === acc.qty_boxed ? "#ddeec5" : "" }}>
                            <td>AC{acc.id}</td>
                            <td>{acc.inventory_sku}</td>
                            <td>{acc.portal_accesssory_name}</td>
                            <td style={{ textAlign: "center" }}>{acc.qty_ordered}</td>
                            <td style={{ backgroundColor: props.rowColor, textAlign: "center" }}>{acc.qty_boxed}</td>
                        </tr>
                    )
                })
            }
        </>
    );
}


//
//  Create Zoho Shipment Modal
//
const CreateZohoShipmentModal = (props) => {

    const { setQuoteIdsToShip, quoteIdsToShipRef } = props;

    const [deliveryMethod, setDeliveryMethod] = useState("");
    const [trackingNumber, setTrackingNumber] = useState("");
    const [shippingCharge, setShippingCharge] = useState(0.0);
    const [shippingNotes, setShippingNotes] = useState("");



    // This is an input field so it's really a string.
    const [newQuoteNumber, setNewQuoteNumber] = useState("");

    const [order, setOrder] = useState(props.order);
    const [isFreight, setIsFreight] = useState(false);
    const [adding, setIsAdding] = useState(false);

    const onItemCheck = (e, item) => {
        item.selected = e.target.checked;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (shippingCharge == order.id) {
            alert("Shipping Charge musn't equal the PS Number. If this message is seen in error, please contact enginneering.")
        } else {
            let selectedPackageIds = [];



            props.packages.map(i => {

                console.log("itemSubmit", i);
                if (i.selected) {
                    selectedPackageIds.push(i.package_id)
                }
            });

            createZohoShipment(selectedPackageIds, props.shipments);
            props.toggle();
        }


    };
    const quoteIdList = quoteIdsToShipRef.current ?? [];

    const createZohoShipment = (selectedPackageIds, shipments) => {

        console.log("selectedPackageIds", selectedPackageIds);
        console.log("shipments", shipments);

        let boxNum = shipments.length + 1;
        console.log("boxNum", boxNum);

        let vPayload = {
            "quote_ids": quoteIdList,
            "ordering_entity": "PS",
            "shipment_number": boxNum.toString(),
            "notes": shippingNotes,
            "delivery_method": deliveryMethod,
            "tracking_number": trackingNumber,
            "shipping_charge": shippingCharge,
            "is_freight": isFreight,
            "package_ids_in_shipment": selectedPackageIds ?? []
        };

        // if any package Ids were selected. If not, create shipment will create a package with all items and then create a shipment.
        //if (selectedPackageIds.length > 0) {
        //    vPayload = { ...vPayload, "package_ids_in_shipment": selectedPackageIds }
        //}
        console.log("vPayload", vPayload);

        //Create the Package in Zoho
        MfgApiService.post("/api/commerceapi/shipments/create", vPayload).then(
            response => {
                console.log("Created Shipment", response);
                //populateOrderData();
            }
        ).catch(error => console.error("error", error));
    }

    const onModalOpened = () => {

        // clear the state variables
        setDeliveryMethod("");
        setTrackingNumber("");
        setShippingCharge(0.0);
        setShippingNotes("");
        setOrder({});

        // run the external function
        props.onOpened();
    }

    const title = "Shipments";

    const label = "Ship Order(s) : " + quoteIdList.reduce((prev, qid) => prev == null ? `${qid}` : `${prev},${qid}`, null);

    const quoteIdLis = quoteIdList.map(qid => {
        return (<li>
            {`PS${qid}`}
        </li>)
    })

    const setToAdding = () => {
        setIsAdding(true);
    }

    const cancelAdding = () => {
        setIsAdding(false);
        setNewQuoteNumber("");
    }

    const addQuote = (quote_id_str) => {

        const quote_id = parseInt(quote_id_str)

        // Add Quote
        const newList = [...quoteIdList, quote_id];

        // Clear duplicates. GIGO
        const newListToSend = [... new Set(newList)]

        setQuoteIdsToShip(newListToSend);

        cancelAdding();
    }

    // If we aren't adding, the button to add is there. If we are adding the user can type.'
    if (!adding) {
        quoteIdLis.push((
            <li>
                <button onClick={() => setToAdding()}>Add Quote +</button>
            </li>
        ))
    } else {
        quoteIdLis.push((
            <li>
                <input title="Enter new Quote #" value={newQuoteNumber} onChange={(e) => setNewQuoteNumber(e.target.value)}></input>
                <button onClick={() => addQuote(newQuoteNumber)}>Add Quote</button>
                <button onClick={() => cancelAdding()}>Cancel</button>
            </li>
        ))
    }
    return (
        <Modal onOpened={onModalOpened} isOpen={props.isOpen} toggle={props.toggle} fade={true} size="xl" backdrop="static" aria-labelledby="contained-modal-title-vcenter" style={{ transform: "none" }}>
            <ModalBody className="text-center">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h3">Ship Order(s) </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Label tag="h4">{label}</Label>
                        <p style={{ fontStyle: "italic" }}>Packages will automatically be created if none are selected.</p>
                        <Table
                            className="-striped -highlight"

                        >
                            <thead>
                                <tr>
                                    <th className="header">Select</th>
                                    <th className="header">Pkg Id</th>
                                    <th className="header">Date</th>
                                    <th className="header">Package #</th>
                                    <th className="header">Sales Order #</th>
                                    <th className="header">Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.packages.map(p => {
                                        return (
                                            <tr key={p.package_id} >
                                                <td><input disabled={p.status === "shipped"} style={{ width: "20px", height: "20px", margin: "auto", verticalAlign: "center", display: "flex" }}
                                                    type="checkbox" onChange={(e) => onItemCheck(e, p)} id={"rowcheck" + p.package_id}
                                                >
                                                </input></td>
                                                <td>{p.package_id}</td>
                                                <td>{p.date}</td>
                                                <td>{p.package_number}</td>
                                                <td>{p.salesorder_number}</td>
                                                <td>{p.status}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <Row><br /></Row>
                        <Row>
                            <ul>
                                {quoteIdLis}
                            </ul>
                        </Row>
                        <Row><br /></Row>
                        <Row>
                            <Col md="6">
                                <Label>Carrier</Label>
                                <FormGroup>
                                    <Input
                                        type="select"
                                        className="info"
                                        name="selectDeliveryMethod"
                                        value={deliveryMethod}
                                        onChange={(e) => {
                                            setDeliveryMethod(e.target.value);
                                            console.log(e.target.value);
                                        }}
                                        placeholder="Select Delivery Method"
                                    >
                                        {props.deliveryMethodList.map(dm => {
                                            return (<option>{dm}</option>)
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <Label>Tracking Number</Label>
                                <FormGroup>
                                    <Input type="text"
                                        value={trackingNumber}
                                        onChange={(e) => setTrackingNumber(e.target.value)}
                                    />
                                </FormGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Label>Shipping Charge</Label>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Input type="number"
                                            value={shippingCharge}
                                            onChange={(e) => setShippingCharge(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md="6">
                                <Label>Notes</Label>
                                <FormGroup>
                                    <Input type="textarea"
                                        value={shippingNotes}
                                        onChange={(e) => setShippingNotes(e.target.value)}
                                    />
                                </FormGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Label>Is this Shipping Freight?</Label>
                                <FormGroup>
                                    <Input type="select" onChange={(e) => setIsFreight(e.target.value)}>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </Input>
                                </FormGroup>

                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary" className="pull-right" onClick={handleSubmit}>Create Shipment</Button>
                        <Button color="danger" className="pull-left" onClick={props.onCancel}>Cancel</Button>
                    </CardFooter>
                </Card>
            </ModalBody>

        </Modal>
    );

}

export default ShippingStation;